import React, { useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainNavigation from '../components/Navigation/MainNavigation.js';
import ArrowNavigation from '../components/Navigation/ArrowNavigation.js';
import ModalInit from '../components/Overlays/ModalInit';
import ModalPassword from "../components/Overlays/ModalPassword";
import Footer from '../components/Navigation/Footer';

import { gsap } from "gsap";

import './Root.sass';

const RootLayout = () => {

	const location = useLocation();

	useLayoutEffect(() => {
		setTimeout(() => {
			gsap.set(window, { scrollTo: 0 });
		}, 100);
	}, [location]);

	return (
		<>
			<MainNavigation/>
			<ArrowNavigation />
			<main className='page-content'>
				<Outlet />
			</main>
			<ModalInit></ModalInit>
			<ModalPassword></ModalPassword>
			<Footer></Footer>
		</>
	);
}

export default RootLayout;
