import React, {useState, useRef} from 'react';

const ModalPassword = () => {

    const input = useRef(null);

    const [showModalPassword, setShowModalPassword] = useState(true);
    const [feedback, setFeedback] = useState(null);

    const handleClickButton = ({currentTarget}) => {
        
        const password = 'pzb0jqy_MGM4yku2etj';
        if(input.current.value === password){
            setShowModalPassword(false);
            const cookieName = 'password';
            const cookieValue = true;
            setSessionCookie(cookieName, cookieValue);

        } else {
            
            setFeedback('Falsches Passwort!');
        }
    }

    const isSessionCookieExist = (cookieName) => {
        return document.cookie.split('; ').some((cookie) => {
            return cookie.startsWith(`${cookieName}=`);
        });
    };

    const setSessionCookie = (cookieName, cookieValue) => {
        document.cookie = `${cookieName}=${cookieValue}`;
    }

    return (
        <>
            { (!isSessionCookieExist('password')) ? (
                <div className={`modal modal--password modal--animate-scale ${(showModalPassword) ? 'modal--is-visible' : '' }`} >
                    <div className='modal__form'>
                        <div>
                            <input ref={input} type="password" name="password" id="password" placeholder="Passwort" />
                        </div>
                        <div>
                            <button onClick={handleClickButton} className="btn btn--purple-light">Anmelden</button>
                            { (feedback) ? <p>{feedback}</p> : <></> }
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
        
    );
};
export default ModalPassword;